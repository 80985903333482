import { mapActions } from 'vuex';
export const metricsLogic = {
  methods: {
    ...mapActions({
      getProductRating: 'getProductRating',
      getWebsiteProductStatuses: 'getWebsiteProductStatuses',
      updateProductRating: 'updateProductRating',
      getWeightings: 'getWeightings',
      updateWeightings: 'updateWeightings',
    })
  },
};
