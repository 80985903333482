<template>
  <v-row class="ma-n2">
    <v-col>
      <v-row class="ma-0">
        <v-switch v-model="settings.use_settings" label="Process Images" class="mx-auto mt-n2 pt-0 pb-2" hide-details :disabled="!$store.getters.getOrganizationSettings.module_image" inset></v-switch>
      </v-row>
      <v-divider/>
      <v-row class="ma-0">
        <v-slider v-model="settings.quality" label="Quality" min=0.1 max=1 step=0.1 thumb-label :tick-labels="tick_labels" :disabled="!settings.use_settings"></v-slider>
      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field v-model.number="width" label="Width" type="number" class="rounded-lg pb-2" :rules="required_field_rules" outlined hide-details="auto" :disabled="!settings.use_settings"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model.number="height" label="Height" type="number" class="rounded-lg pb-2" :rules="required_field_rules" outlined hide-details="auto" :disabled="!settings.use_settings"></v-text-field>
        </v-col>
        <v-col cols=1 class="pt-5">
          <v-icon @click="aspectRatio()">{{ lock_aspect_ratio ? "mdi-lock-outline" : "mdi-lock-open-variant-outline" }}</v-icon>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-select v-model="settings.resize" :items="resize_options" label="Resize Type" class="rounded-lg pb-0" hint="How the image is resized to fit the specified height and width." persistent-hint outlined hide-details="auto" :disabled="!settings.use_settings">
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }} </v-list-item-title>
                  <v-list-item-subtitle >{{ item.tooltip }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action class="ml-0">
                  <v-img :src="item.image_url" height="40px" width="100px" contain></v-img>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col>
          <v-select v-model="settings.mime_type" :items="image_types" label="Image Type" class="rounded-lg pb-0" hint="Convert all images to specified type." persistent-hint outlined hide-details="auto" :disabled="!settings.use_settings">
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }} </v-list-item-title>
                  <v-list-item-subtitle >{{ item.tooltip }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>

    <v-divider vertical class="my-n2"/>

    <v-col class="pt-0">
      <v-row dense>
        <v-col style="margin: auto">
          <v-checkbox v-model="settings.fill_background" hide-details="auto" class="mt-0" hint="On image resize, fill additional space with color." persistent-hint :disabled="!settings.use_settings">
            <template v-slot:label>
              <span v-if="settings.fill_background">Fill Background</span>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                  <span v-on="on">Fill Background</span>
                </template>
                <span>By default fills background with white.</span>
              </v-tooltip>
            </template>
          </v-checkbox>
        </v-col>
        <v-col style="margin: auto">
          <v-sheet class="rounded-lg pa-2" :color="settings.background_color" style="border: thin solid rgba(0, 0, 0, 0.4);">
            <span class="white-border font-weight-black text-uppercase d-flex justify-center align-center">Fill Color</span>
          </v-sheet>
        </v-col>
      </v-row>
      
      <v-row class="mt-0">
        <v-col id="color_picker">
          <v-color-picker
            v-if="picker_element"
            v-model="settings.background_color"
            :disabled="!settings.fill_background || !settings.use_settings"
            mode="hexa"
            :width="pickerWidth"
            hide-mode-switch
          ></v-color-picker>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    settings: Object,
    use_settings: Boolean,
  },
  data() {
    return {
      lock_aspect_ratio: true,
      aspect_ratio: this.settings.width / this.settings.height,
      picker_element: null,
      required_field_rules: [v => (!!v || v===0) || 'Must be a valid number.', v => v <= 10000 || 'Cannot be greather than 10000.'],
      tick_labels: ['Low', '', '', '', 'Medium', '', '', 'Default', '', 'High'],
      resize_options: [
        { text: 'None', value: 'none', tooltip: 'Maintain original aspect ratio.', image_url: 'https://res.cloudinary.com/breezy-software/image/upload/v1658972352/assets/Resize_None.png' },
        { text: 'Fit To Size', value: 'contain', tooltip: 'Adjusts longest edge to new dimensions and fills additional space with background color.', image_url: 'https://res.cloudinary.com/breezy-software/image/upload/v1658972352/assets/Resize_FitToSize.png' },
        { text: 'Crop To Size', value: 'cover', tooltip: 'Adjusts shortest edge to new dimensions and crops remainder.', image_url: 'https://res.cloudinary.com/breezy-software/image/upload/v1658972352/assets/Resize_CropToSize.png' },
      ],
      image_types: [
        { text: 'Auto', value: 'auto', tooltip: 'Retains original type of the image.' },
        { text: 'JPEG', value: 'image/jpeg', tooltip: 'Convert all images to JPEG type.' },
        { text: 'PNG', value: 'image/png', tooltip: 'Convert all images to PNG type.' },
        { text: 'WEBP', value: 'image/webp', tooltip: 'Convert all images to WEBP type.' },
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.picker_element = document.getElementById("color_picker");
    });
  },
  computed: {
    width: {
      get() {
        return this.settings.width;
      },
      set(value) {
        this.settings.width = value;
        if (this.lock_aspect_ratio) {
          this.settings.height = value / this.aspect_ratio;
        }
      }
    },
    height: {
      get() {
        return this.settings.height;  
      },
      set(value) {
        this.settings.height = value;
        if (this.lock_aspect_ratio) {
          this.settings.width = value * this.aspect_ratio;
        }
      }
    },
    pickerWidth() {
      return this.picker_element?.clientWidth ? `${this.picker_element?.clientWidth}px` : '300px';
    }
  },
  methods: {
    aspectRatio() {
      this.lock_aspect_ratio = !this.lock_aspect_ratio;
      if (this.lock_aspect_ratio) {
        this.aspect_ratio = this.settings.width / this.settings.height;
      }
    }
  }
}
</script>

<style>
.white-border {
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}
.v-color-picker__dot {
  display: none;
}
.v-color-picker__canvas {
  border-radius: 0.5rem;
}
.v-color-picker__canvas > canvas {
  border-radius: 0.5rem;
  border: thin solid rgba(0, 0, 0, 0.5);
}
.v-color-picker__controls {
  padding-bottom: 0px;
}
.v-color-picker__edit {
  margin-top: 8px;
}
.v-color-picker__edit > .v-color-picker__input > span {
  display: none;
}
</style>